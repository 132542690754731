// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-download-jsx": () => import("./../src/pages/download.jsx" /* webpackChunkName: "component---src-pages-download-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-links-jsx": () => import("./../src/pages/links.jsx" /* webpackChunkName: "component---src-pages-links-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

